@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"




































































.video-modal

  .modal

    +mq-m
      padding: 0

    &__title

      +mq-m
        margin: 0
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14)

        h3
          box-sizing: border-box
          padding: 16px 32px

  &__body
    margin-bottom: 48px

    +mq-l
      position: relative
      padding-bottom: 56.25%

    +mq-m
      margin-top: 24px

    iframe
      border: none
      padding: 16px 0
      -webkit-box-sizing: border-box
      box-sizing: border-box

      +mq-l
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        padding: 0

  .modal__footer
    justify-content: space-between
